<template lang='pug'>
article.degustations
  .degustations__cont.cont
    h1.degustations__caption.caption
      | Дегустации
    p.degustations__lid.text-big
      | Не хотите экскурсию? У вас есть возможность заказать дегустацию без экскурсии!
    p.degustations__lid.text-big
      | На дегустации вы попробуете настоящий бельгийский шоколад под небольшой рассказ Хранительницы про каждый вид
    p.degustations__lid.text-big
      | Вашему вниманию предлагается несколько вариантов дегустационных программ, разных по наполнению стоимости,
      | чтобы каждый нашел для себя подходящий!
    h2.degustations__title.title «7 вкусов шоколада»
    p.degustations__text.text-big
      | Окунитесь в мир истинного наслаждения и попробуйте 7 вкусов настоящего бельгийского шоколада под интереснейший
      | рассказ Хранительницы музея!
    .degustations__wrapper
      .degustations__group.degustations__group_single
        h3.degustations__headline.headline В программу входит:
        ul.degustations__list
          li.degustations__item.text-big
            | 7 вкусов шоколада
          li.degustations__item.text-big
            | чашка чая или кофе на выбор
          li.degustations__item.text-big
            | скидка 10% на первую интернет-покупку на сайте Музея
      .degustations__group
        span.degustations__duration.text-big
          | Продолжительность 30 минут
        p.degustations__text.text-big
          | Стоимость 850 ₽/чел
    h2.degustations__title.title «Шоколадное искушение»
    p.degustations__text.text-big
      | Дегустации в Музее «Шоколадный Выборг» - это настоящее искушение! Убедитесь в этом сами!
    .degustations__wrapper
      .degustations__group.degustations__group_single
        h3.degustations__headline.headline В программу входит:
        ul.degustations__list
          li.degustations__item.text-big
            | 7 вкусов шоколада
          li.degustations__item.text-big
            | авторские конфеты музея
          li.degustations__item.text-big
            | чашка чая или кофе на выбор
          li.degustations__item.text-big
            | скидка 10% на первую интернет-покупку на сайте Музея
      .degustations__group
        span.degustations__duration.text-big
          | Продолжительность 30 минут
        p.degustations__text.text-big
          | Стоимость 950 ₽/чел
    h2.degustations__title.title «Трюфель - самая роскошная из конфет»
    p.degustations__text.text-big
      | Закройте глаза, откусите кусочек и ощутите всю уникальность этого необыкновенного десерта!
    .degustations__wrapper
      .degustations__group.degustations__group_single
        h3.degustations__headline.headline В программу входит:
        ul.degustations__list
          li.degustations__item.text-big
            | 7 видов авторских трюфелей
          li.degustations__item.text-big
            | чашка чая или кофе на выбор
          li.degustations__item.text-big
            | рецепт классического бельгийского трюфеля
          li.degustations__item.text-big
            | скидка 10% на первый интернет-заказ на сайте Музея
      .degustations__group
        span.degustations__duration.text-big
          | Продолжительность 30 минут
        p.degustations__text.text-big
          | Стоимость 1200 ₽/чел
    h2.degustations__title.title «Королевский стол»
    p.degustations__text.text-big
      | Почувствуйте себя королевской особой и насладитесь разнообразием лакомств, созданных шоколатье!
    .degustations__wrapper.degustations__wrapper_last
      .degustations__group.degustations__group_single
        h3.degustations__headline.headline В программу входит:
        ul.degustations__list
          li.degustations__item.text-big
            | 7 вкусов шоколада
          li.degustations__item.text-big
            | авторские конфеты музея
          li.degustations__item.text-big
            | чашка чая или кофе на выбор
          li.degustations__item.text-big
            | шоколадный фонтан с фруктами
          li.degustations__item.text-big
            | рецепт классического бельгийского трюфеля от музея
          li.degustations__item.text-big
            | скидка 10% на первую интернет-покупку на сайте Музея
      .degustations__group
        span.degustations__duration.text-big
          | Продолжительность 45 минут
        p.degustations__text.text-big
          | Стоимость 2800 ₽/чел
    event-contact
    event-gallery(
      :images="images",
      headline="Экскурсия в Музее «Шоколадный Выборг»",
      text="Посетители услышали легенды и необычные истории о шоколаде, а также продегустировали различные виды шоколада")
</template>

<script>
import '@/assets/styles/components/degustations.sass'
import EventContact from '../EventContact'
import EventGallery from '../EventGallery'

export default {
  name: 'TheDegustations',
  components: { EventGallery, EventContact },
  data () {
    return {
      images: [
        { path: '/images/photo-gallery/photo-gallery-15', alt: 'Групповые экскурсии в Музее «Шоколадный Выборг»', mod: 'small' },
        { path: '/images/photo-gallery/photo-gallery-16', alt: 'Внутренняя экспозиция в Музее «Шоколадный Выборг» во время экскурсии', mod: 'small' },
        { path: '/images/photo-gallery/photo-gallery-17', alt: 'Дегустация шоколада на экскурсии в Музее «Шоколадный Выборг»', mod: 'small' },
        { path: '/images/photo-gallery/photo-gallery-18', alt: 'Экскурсия и дегустация с возможностью приобрести шоколад', mod: 'small' },
        { path: '/images/photo-gallery/photo-gallery-19', alt: 'Экскурсанты в Музее «Шоколадный Выборг»', mod: 'small' }
      ]
    }
  }
}
</script>
