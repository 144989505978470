<template lang='pug'>
head-meta(
  title-page="Экскурсии и дегустации – Музей «Шоколадный Выборг»",
  description="Кулинарные мастер - классы по изготовлению шоколадных фигурок, дегустационные вечера, экскурсии и тематические мероприятия. Расписание, цены и контакты."
)
the-degustations
contacts-line
</template>

<script>
import TheDegustations from '../components/degustations/TheDegustations'
import HeadMeta from '../components/_layout/HeadMeta'
import ContactsLine from '../components/_layout/TheContactsLine'

export default {
  name: 'DegustationsPage',
  components: { ContactsLine, TheDegustations, HeadMeta }
}
</script>
